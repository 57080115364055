/* Add this CSS code to your NavBar.css */

/* ... Your existing CSS styles ... */

/* Adjust the width and center the navbar */
.navbar {
    background-color: #222;
    height: 60px;
    display: flex;
    justify-content: space-between; /* Align items to the start and end */
    align-items: center;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 20;
    font-weight: 600;
    text-transform: uppercase;
    width: 80%; /* Adjust the width as needed */
    margin: 0 auto; /* Center the navbar horizontally */
    margin-bottom: -35px;
    border-radius: 0; /* Remove border radius */
  }
  
  .header-search {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 0 12px;
    background-color: transparent;
    width: 250px;
    margin-right: 10px;
}

.header-search input[type="text"] {
    border: none;
    padding: 6px ;
    width: 210px;
    background-color: transparent;
    outline: none;
    color: #fff;
}

.header-search input[type="text"]:focus {
    border: none;
    box-shadow: none;
}

.search-icon {
    color: #ccc;
    cursor: pointer;
    font-size: 18px;
    margin-left: 4px;
}
  
  /* Adjust the margin-left for .nav-menu */
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-left: 2rem;
  }
  .nav-divider {
    flex-grow: 1; /* Let it grow and occupy available space */
    height: 2px; /* Height of the divider */
    background-color: transparent; /* Divider color */
    margin: 0 1rem; /* Add spacing on both sides */
  }
  
  /* ... Other existing CSS styles ... */
  
  .nav-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
  }
.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #F5BF23;
}

.nav-item .active {
  color: #F5BF23;
}

.nav-icon {
  display: none;
}
@media screen and (max-width: 768px) {
  .header-search {
    width: 150px; /* Adjust width for smaller screens */
    margin-right: 10px; /* Adjust margin for spacing */
  }

  .header-search input[type="text"] {
    width: 120px; /* Adjust width for smaller screens */
  }
  .search-icon {
    
    font-size: 15px;
    margin-left: -8px;
}
}
@media screen and (max-width: 960px) {
    .nav-item{
        border-bottom: 1px solid #eee;

    }
    .nav-links{
        color: #222;
    }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -120%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #FFF;
    left: -20px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    top: 60px;
  }
  .nav-item .active {
    color: #F5BF23;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    padding-right: 10px;
    margin-left: 20px;
    display: block;
    /* position: absolute; */
    top: 0;
    /* transform: translate(200%, 60%); */
    font-size: 30px;
    cursor: pointer;
    color: #FFF;
    border-right: 1px solid #fff;
    margin-right: 10px;
  }
}





.nav-item:hover>.sub-menu {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.sub-menu {
	background-color: #ffffff;
	border: 1px solid #f4f4f4;
	display: block;
	left: 0;
	top: 100%;
	list-style: none;
	margin: 0px 0 0;
	opacity: 0;
	padding: 0px;
	position: absolute;
	visibility: hidden;
	width: 220px;
	z-index: 10;
}

.sub-menu li {
	position: relative;
	text-transform: none;
}

.sub-menu li a {
	color: #000;
	display: block;
	font-size: 14px;
	padding: 14px 20px;
	font-weight: 400;
	border-left:0px solid transparent;	
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;	
}

.sub-menu li a:hover {
	color: #000;
	text-decoration: none;
	border-left:10px solid  #F5BF23;	
}

.sub-menu li a:hover:after {
	width: 4px;
	left: -1px;
}

.sub-menu li:hover>a {
	color: #000;
	background-color: #f6f6f6;
}

.sub-menu li:last-child {
	border-bottom: 0px;
}

.sub-menu li .fa {
	color: grey;
	display: block;
	float: right;
	position: absolute;
	right: 10px;
	top: 30px;
  font-size: 10px;
}

.sub-menu li:hover .fa {
	color: #000;
}

.sub-menu li>.sub-menu {
	left: 220px;
	top: 0px;
	margin: 0 0 0 0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.sub-menu li:hover>.sub-menu {
	left: 220px;
	margin: 0px;
	opacity: 1;
	top: -1px;
	visibility: visible;
}

.sub-menu li:hover>.sub-menu:before {
	background-color: transparent;
	bottom: 0px;
	content: '';
	display: block;
	height: 100%;
	left: -6px;
	position: absolute;
	top: 0px;
	width: 6px;
}

/* Your existing CSS styles... */

/* Submenu styles for responsive design */
/* Submenu styles for responsive design */
/* Styles for max-width: 960px */
/* Styles for max-width: 960px */
@media screen and (max-width: 960px) {
  .nav-menu {
    flex-direction: column;
  }

  .nav-item {
    position: relative; /* Ensure submenu is positioned relative to the nav-item */
  }

  .sub-menu {
    background-color: #ffffff;
    border: 1px solid #f4f4f4;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; /* Ensure submenu matches width of nav-item */
    max-height: 0; /* Start with zero height */
    overflow: hidden; /* Hide content when not visible */
    opacity: 0; /* Start with zero opacity */
    visibility: hidden; /* Hide the submenu */
    position: relative; /* Ensure submenu expands within the nav-item */
    transition: max-height 0.3s ease, opacity 0.3s ease;
    z-index: 10;
    box-sizing: border-box;
  }

  .nav-item.active .sub-menu,
  .nav-item:hover .sub-menu,
  .nav-item:focus-within .sub-menu {
    opacity: 1;
    visibility: visible;
    max-height: 1000px; /* Adjust as needed */
  }

  .sub-menu li {
    width: 100%; /* Ensure submenu items take the full width */
  }

  .sub-menu li a {
    padding: 10px 20px;
    border-left: 0;
    text-align: left;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .sub-menu li .sub-menu {
    position: static; /* Ensure child sub-menus are positioned relative to their parent */
    display: none; /* Hide child sub-menus by default */
    width: 100%; /* Match width of the parent submenu */
  }

  .sub-menu li:hover .sub-menu {
    display: block; /* Show child sub-menus when hovering over parent */
  }
}

/* Styles for max-width: 768px */
@media screen and (max-width: 768px) {
  .nav-menu {
    flex-direction: column;
  }

  .nav-item {
    position: relative; /* Ensure submenu is positioned relative to the nav-item */
  }

  .sub-menu {
    background-color: #ffffff;
    border: 1px solid #f4f4f4;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    position: relative; /* Ensure submenu expands within the nav-item */
    transition: max-height 0.3s ease, opacity 0.3s ease;
    z-index: 10;
    box-sizing: border-box;
  }

  .nav-item.active .sub-menu,
  .nav-item:hover .sub-menu,
  .nav-item:focus-within .sub-menu {
    opacity: 1;
    visibility: visible;
    max-height: 1000px; /* Adjust as needed */
  }

  .sub-menu li {
    width: 100%;
  }

  .sub-menu li a {
    padding: 10px 20px;
    border-left: 0;
    text-align: left;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .sub-menu li .sub-menu {
    position: static;
    display: none;
    width: 100%;
  }

  .sub-menu li:hover .sub-menu {
    display: block;
  }
}


.header-search.clicked {
  box-shadow: 0 0 5px rgba(245, 191, 35, 0.5); 
  border-color: #F5BF23  ;
}


/* For screens between 1145px and 960px */
@media screen and (max-width: 1145px) and (min-width: 960px) {
  .nav-links {
    font-size: 10px;  /* Make the label smaller */
  }

  .nav-item {
    font-size: 10px; /* Also reduce font size for the items */
  }
  
  .nav-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding: 0; /* Adjust padding as needed */
  }

  .nav-item {
    border-bottom: none; /* Keep the border off for this range */
  }

  /* Optionally you can tweak the padding or margins too */
  .nav-links {
    padding: 0.8rem;  /* Adjust the padding to make it tighter */
  }
  
  .nav-icon {
    display: none; /* Hide hamburger icon for this range */
  }
}
