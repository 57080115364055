body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.video-intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.video-intro.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}


.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  margin-top: 5px;
  margin-left: 15px;
}

.progress {
  height: 100%;
  background-color: #F5BF23;
  transition: width 0.2s ease;
}
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7); /* Slightly dimmed background */
  z-index: 10;
}

.spinner-container {
  position: relative;
  width: 100%;
  height: 100%; /* Make sure it takes up the full height of the parent */
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #F5BF23;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
